import dynamic from 'next/dynamic';
import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "next-i18next";
import { Accordion, AccordionSummary } from '@material-ui/core';
import CommentItemChild from "./CommentItemChild";
import styles from './Comments.module.scss';
import hooks from "./commentsHooks";
const DynamicCommentsInput = dynamic(() => import('./CommentsInput'))
function CommentItem(props) {
    const {
        comments,
        expanded,
        translate,
        moment,
        setExpand
    } = hooks(props)
    const { t } = useTranslation();
    return (
        <div>
            <div className={styles.comment_item}>
                {props?.item?.map((item, index) => {
                    return <div className={styles.comment_data} key={index}>
                        <div className=" d-flex flex-column ml-3 comment-item">
                            <div className="d-flex">
                                <img src={item.user_details.image
                                    ? item.user_details.image
                                    : "/profile-user.svg"} />
                                <div className="d-flex flex-column justify-content-center">
                                    <div className={styles.comment_name}>
                                        <span className={styles.name}>
                                            {item.user_details.first_name
                                                ? item.user_details.first_name + " "
                                                : ""}{item.user_details.last_name
                                                    ? item.user_details.last_name
                                                    : ""}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.text}>{item.comment} </div>
                            <div className={styles.text_bottom_data}>
                                <Accordion expanded={expanded === `panel${index}`}
                                    onChange={() => setExpand(`panel${index}`)}>
                                    {/* <div className={styles.text_bottom_data}> */}
                                    <AccordionSummary>
                                        {props.token !== "" && <div className="d-flex flex-column">
                                            <div className={styles.answer}>
                                                <span>{moment(item.created_at).startOf().fromNow()}</span>
                                                {props.hide_input
                                                    ? null
                                                    : <span>{t("answer")}</span>}
                                            </div>
                                            <div className={styles.child_comment_data}>
                                                {item.child_comments.map((el, ind) => {
                                                    return <CommentItemChild key={ind} item={el} />
                                                })}
                                            </div>
                                        </div>}

                                    </AccordionSummary>
                                    {/* </div> */}
                                    <DynamicCommentsInput
                                        // id={props.item.id}
                                        // value={comments[props.item.id].new_comment_value}
                                        // value={props.item.value}
                                        placeholder={t("addCommentLabel")}
                                        my_image={props.my_image}
                                        value={props.value}
                                        onChange={(value) => props.onChange(value)}
                                        // set_child_comment(value)}
                                        readOnly={comments.disabled}
                                        onClick={() => {
                                            props.onClick(item.id)
                                            // add_loading()
                                            // add_child_comment({ comment_id: item.id, post_id: props.id })
                                        }}
                                    />
                                    <div className={styles.border_style}>
                                        <hr />
                                    </div>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                })}

            </div>
            {props.item && props.item.more_loading
                ? <div className="w-100 d-flex justify-content-center mt-3">
                    <ClipLoader color="#464646" size={15} />
                </div>
                : null}
            {props?.item?.count - props?.item?.results?.length > 0
                ? <span className={styles.title_see_moree}
                    onClick={() => {
                        // posts_comment_loader({ post_id: props.item.id, more_loading: true })
                        // get_comments()
                        props.get_comments_more()
                    }}
                >Показать
                    {props.item.count - props.item.results.length}
                    {t("answers")}</span>
                : null}
        </div>
    );
};
export default CommentItem;
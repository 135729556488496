import React from 'react'
import styles from './Comments.module.scss';
import hooks from "./commentsHooks";

function CommentItemChild(props) {
    const {moment} = hooks(props)
    return (
        <div className={styles.comment_data}>
            <div className="d-flex flex-column ml-3">
                <div className="d-flex">
                    <img src={props.item.user_details.image
                        ? props.item.user_details.image
                        : "/profile-user.svg"} />
                    <div className="d-flex flex-column justify-content-center">
                        <div className={styles.comment_name}>
                            <span className={styles.name}>
                                {props.item.user_details.first_name + " "}
                                {props.item.user_details.last_name}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.text}>
                    {props.item.comment}
                </div>
                <div className={styles.child_comment_text_bottom_data}>
                    <span>{moment(props.item.created_at).startOf().fromNow()}</span>
                </div>
            </div>
        </div>
    );
};
export default CommentItemChild;